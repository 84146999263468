<template>
  <loading :show="isLoading">
    <b-card>
      <b-table
          ref="table"
          :current-page="currentPage"
          :empty-text="$i18n.t('table.no_data')"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          show-empty
      >
        <template #cell(value)="data">
          <b-form-textarea
              :key="data.item.name"
              v-model="data.item.value"
          />
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="items.length"
          class="my-0 ml-auto"
      />
      <b-button
          class="float-md-right m-1"
          type="submit"
          variant="primary"
          @click="save()"
      >
        {{ $i18n.t('save') }}
      </b-button>
    </b-card>
  </loading>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      items: [],
      isLoading: false,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      groups: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$i18n.t('fotostudio.brand-description.fields.name'),
          sortable: true
        },
        {
          key: 'value',
          label: this.$i18n.t('fotostudio.brand-description.fields.value'),
          sortable: false
        },
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const res = await this.$http.get('settings/brand-description')
      this.items = res.data.data
      this.isLoading = false
    },
    save() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$http.post('/settings/brand-description', {data: this.items}).then(response => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        }).catch(error => {
          this.isLoading = false
          if (error.data && error.data.errors) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'TrashIcon',
                variant: 'danger',
                text: error.data.message,
              },
            })
          }
        })
      }
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
